import React from "react"
import TemplatePageGuide  from "@components/pageGuide/template"
import { Helmet } from "react-helmet"
import data from "@components/pageGuide/guides/guide29/dataPageGuide29"

const IndexPage = ({ location }) => (
  <div className="fnd">
     <Helmet htmlAttributes={{ lang: 'es' }}>
      <title>
      Guía para una correcta capacitación en atención al cliente
       [Antes Securitec]</title>
      <link
        rel="icon"
        type="image/png"
        href={`https://beexcc.com/Beex.png`}
      />
      <link
        rel="canonical"
        href={`https://beexcc.com/recursos/guias/capacitacion-atencion-cliente/`}
      />
      <meta name="robots" content="index, follow"></meta>
      <meta charSet="utf-8" />
      <meta
        name="description"
        content="Te contamos cómo puedes mejorar tus procesos de capacitación en atención al cliente de todos tus agentes. Así, mejoras la interacción de con todos tus agentes."
      />
      <meta property="og:locale" content="es_ES" />
      <meta property="og:type" content="website" />
      <meta
        property="og:title"
        content="Guía para una correcta capacitación en atención al cliente"
      />
      <meta
        property="og:url"
        content={`https://beexcc.com/recursos/guias/capacitacion-atencion-cliente/`}
      />
      <meta
        property="og:image"
        content={`https://beexcc.com/metaimagenRecursos/Guias/og-imgGuia29.jpg`}
      />
      <meta
        property="og:description"
        content="Te contamos cómo puedes mejorar tus procesos de capacitación en atención al cliente de todos tus agentes. Así, mejoras la interacción de con todos tus agentes."
      />
    </Helmet>
    <TemplatePageGuide
      ebook="Atención al cliente"
      location={location}
      typeGuide="guide29"
      clickHereHref="https://beexcc.com/blog/noticias-atencion-al-cliente/"
      data={data}
    />
  </div>
)

export default IndexPage
